import Banner from './Banner'
import Name from './Name'
import Profil from './Profil'
import Skills from './Skills'
import Swipe from './Swiper'
import Timeline from './Timeline'
import Contact from './Contact'
import Download from './Download'

function App() {
	return (
		<section>
			<Banner />
			<div className='for-max-width'>
			<Name />
			<Profil />
			<Skills />
			<Swipe />
			<Timeline />
			<Download />
			<Contact />
			</div>
		</section>
	)
}

export default App