import '../sass/Profil.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode,faCircleArrowDown} from '@fortawesome/free-solid-svg-icons'
function Profil(){
    return<div className="profil" id='profil'>
        <br></br>
        <FontAwesomeIcon icon={faCode} size='2xl' style={{color: "#2965FF",}} />
        <h1>MON PROFIL :</h1>
        <p>Etudiant en 3ème année de bachelor informatique chez Toulouse YNOV Campus,<br></br>je suis à la recherche d'une alternance dans le développement web full stack.<br></br>Grâce à ce site,vous pourrez en apprendre plus sur mes compétences et mon expérience professionelle.<br></br>Vous pourrez aussi télécharger mon CV.</p>
        <FontAwesomeIcon icon={faCircleArrowDown} size='2xl' style={{color:"#2965FF"}} />
        <br></br>
    </div>
}
export default Profil