import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5,faCss3Alt,faSass,faReact,faGit,faJs,faPython,faGolang,faNode,faPhp,faSymfony, faJava} from '@fortawesome/free-brands-svg-icons'
import csharp from '../assets/c-sharp.png'
import xd from '../assets/xd.png'
import nginx from '../assets/nginx.png'
import "swiper/css";
import '../sass/Swiper.scss'
import "swiper/css/navigation";
import "swiper/css/pagination"
import { Navigation,Pagination } from "swiper";
function Swipe(){
    return <div className="swiper">
        MES COMPETENCES :<br></br>
        <br></br>
    <Swiper navigation={true}  slidesPerView={1}
        loop={true} modules={[Navigation,Pagination]} pagination={{dynamicBullets: true}} className="mySwiper">
      <SwiperSlide><div className="techno_swiper">
      <h1>HTML</h1>
            <FontAwesomeIcon icon={faHtml5} color='#DD4B25'size='5x'/>
            <progress max={'100'} value={'80'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>CSS</h1>
            <FontAwesomeIcon icon={faCss3Alt} color='#3595CF'size='5x'/>
            <progress max={'100'} value={'75'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>SASS</h1>
            <FontAwesomeIcon icon={faSass} color='#C76395' size='5x'/>
            <progress max={'100'} value={'60'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>JS</h1>
            <FontAwesomeIcon icon={faJs} color='#E8D44D' size='5x'/>
            <progress max={'100'} value={'55'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>REACT</h1>
            <FontAwesomeIcon icon={faReact} color='#5ED3F3' size='5x'/>
            <progress max={'100'} value={'50'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>GIT</h1>
            <FontAwesomeIcon icon={faGit} color='#3F2E00' size='5x'/>
            <progress max={'100'} value={'80'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>PYTHON</h1>
            <FontAwesomeIcon icon={faPython} color='#326998' size='5x'/>
            <progress max={'100'} value={'40'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>GO</h1>
            <FontAwesomeIcon icon={faGolang} color='#00A7D0' size='5x'/>
            <progress max={'100'} value={'40'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>NODE JS</h1>
            <FontAwesomeIcon icon={faNode} color='#7FC728' size='5x'/>
            <progress max={'100'} value={'40'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>ADOBE XD</h1>
            <figure><img src={xd} alt='xd'></img></figure>
            <progress max={'100'} value={'60'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>C#</h1>
            <figure><img src={csharp} alt='c#'></img></figure>
            <progress max='100' value='55'></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>PHP</h1>
            <FontAwesomeIcon icon={faPhp} color='#33417B' size='5x'/>
            <progress max={'100'} value={'70'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>SYMFONY</h1>
            <FontAwesomeIcon icon={faSymfony} color='#000000' size='5x'/>
            <progress max={'100'} value={'60'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>JAVA</h1>
            <FontAwesomeIcon icon={faJava} color='#0D6BB0' size='5x'/>
            <progress max={'100'} value={'50'}></progress>
            <br></br>
        </div></SwiperSlide>
        <SwiperSlide><div className="techno_swiper">
        <h1>NGINX</h1>
            <figure><img src={nginx} alt='nginx'></img></figure>
            <progress max='100' value='60'></progress>
            <br></br>
        </div></SwiperSlide>
    </Swiper>
    <br></br>
    MES EXPERIENCES :
    <br></br>
    <br></br>
  </div>
}
export default Swipe