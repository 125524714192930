import '../sass/Banner.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode} from '@fortawesome/free-solid-svg-icons'
function Banner() {
    return <header>
        <nav> 
        <a href='#'><FontAwesomeIcon icon={faCode} size='2xl' style={{color: "#2965FF",}} /></a>
        <a href='#name'><p>ACCUEIL</p></a>
        <a href='#profil'><p>PROFIL</p></a>
        <a href='#skills'><p>COMPETENCES</p></a>
        <a href='#timeline'><p>EXPERIENCES</p></a>
        <a href='#download'> <p>MON CV</p></a>
        <a href='#contact'> <p>CONTACT</p></a>
       </nav>
       </header>
}
export default Banner