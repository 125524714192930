import '../sass/Timeline.scss'
function Timeline(){
    return <div className="timeline" id='timeline'>
         <div className='left-text'>
         <p className='hidden'>bac 2021 mention bien</p>
        <p>bachelor 1 et 2 chez<br></br>Toulouse YNOV Campus</p>
        <p className='hidden'>stage react/symfony chez NOOUS</p>
        <p>stage react/symfony chez NOOUS</p>
        </div>
        <div className='points'>
           
            <br></br>
            <div className="object">
            <div className='timepoint'>
                <div className='insidepoint'></div>
                
                </div>
                
            </div>
            <div className="object">
            <div className='timepoint'>
                <div className='insidepoint'></div>
                
                </div>
                
            </div>
        <div className="object">
            <div className='timelinecontent'></div>
            <div className='timepoint'>
                <div className='insidepoint'></div>
                </div>
        </div>
        <div className="object">
            <div className='timelinecontent'></div>
            <div className='timepoint'>
                <div className='insidewhitepoint'></div>
                </div>
        </div>
        </div>
        <div className='right-text'>
            <p>bac 2021 mention bien</p>
        <p className='hidden'>bachelor 1 et 2 chez<br></br> Toulouse YNOV Campus</p>
       <p>Projet fish eye festival<br></br><a href='https://www.fisheyefestival.com/' target='blank'> https://www.fisheyefestival.com/</a></p>
        <p className='hidden'>stage react/symfony chez NOOUS</p></div>
       
    </div>
}
export default Timeline