import '../sass/Contact.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { faAt,faPhone,faCode} from '@fortawesome/free-solid-svg-icons'
function Contact(){
    return<div className='contact' id='contact'><h1>ME CONTACTER :</h1>
        <div className='coordonnees'>
        <div className='linkedin'><FontAwesomeIcon icon={faLinkedin} size='2xl' color='#0A66C2' /> <a href='https://www.linkedin.com/in/lilian-schott-42b11125b/' target='blank'><h2>Lilian Schott</h2></a></div>
        <div className='mail'><FontAwesomeIcon icon={faAt} size='2xl' color='white' /> <a href='mailto:lilian.schott.31@gmail.com' target='blank'><h2>lilian.schott.31<br></br>@gmail.com</h2></a></div>
        <div className='phone'><FontAwesomeIcon icon={faPhone} size='2xl' color='#E40909' /> <a href='tel:+33783211346' target='blank'><h2>07 83 21 13 46</h2></a></div>
        </div>
        <br></br>
        <a href='#'><FontAwesomeIcon icon={faCode} size='3x' style={{color: "#2965FF"}} /></a>
        <br></br>
       
    </div>
}
export default Contact