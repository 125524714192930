import '../sass/Download.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import CV from '../assets/CV.pdf'
function Download(){
    return <div className="download" id='download'>
        <h1>TELECHARGER MON CV :</h1>
        <div className="myCV">
            <br></br>
            <a href={CV} download="CV Lilian Schott" target='blank'><FontAwesomeIcon icon={faDownload} size='2xl' color='#00D646' /></a>
            <br></br>
        </div>
    </div>
}
export default Download